


































import { Component, Vue } from 'vue-property-decorator';
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import Textarea from '@/components/Textarea.vue';
import Spinner from '@/components/Spinner.vue';
import Alert from '@/components/Alert.vue';
import database from '@/gateways/database';
import auth from '@/gateways/authentication';
import analytics from '@/gateways/analytics';
import { Poll } from '@/models';

@Component({
  components: {
    Alert,
    Button,
    TextInput,
    Textarea,
    Spinner,
  },
})
export default class PollForm extends Vue {
  private name = '';

  private description = '';

  private error = false;

  private loading = false;

  private async handleSubmit() {
    try {
      this.loading = true;
      const poll: Poll = {
        name: this.name,
        description: this.description,
        author: auth.currentUser ? auth.currentUser.uid : '',
      };
      const { id } = await database.collection('polls').add(poll);
      analytics.logEvent('action', { name: 'create_poll', user: auth.currentUser?.uid });
      this.$router.push({
        name: 'polls-id',
        params: { id },
      });
    } catch (err) {
      this.loading = false;
      this.error = true;
      analytics.logEvent('error', { name: 'create_poll', user: auth.currentUser?.uid, error: err });
    }
  }
}
